import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import bladeStyles from '../bladeItem/bladeItem.module.scss';

export interface BladeLinkProps {
  label: string;
  Icon?: JSX.Element;
  route: string
}

export const BladeLink = ({ Icon, label, route }: BladeLinkProps): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const returnLinkStyle = () => {
    if (pathname === route) return bladeStyles.selected_blade;
  }

  return (
    <Box
      className={`${bladeStyles.blade} ${returnLinkStyle()}`}
      onClick={() => navigate(`${route}`)}
    >
      {Icon || ''}
      {label}
    </Box>
  )
}