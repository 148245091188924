import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { ReactComponent as CogIcon } from '../../common/Styles/svg_icons/Icons_Filled_cog_24px.svg';
import { ReactComponent as HomeIcon } from '../../common/Styles/svg_icons/Icons_Filled_home_24px.svg';
import { BladeMenuItem } from '../../common/Types/BladeMenuTypes';
import { BladeItem } from './bladeItem/BladeItem';
import { selectMenuItems, fetchBladeMenu } from './bladeMenuSlice';
import styles from './bladeMenu.module.scss';
import { fetchUserPreference } from '../overview/preferences/preferencesSlice';
import { BladeLink } from './bladeLink/BladeLink';

export const BladeMenu = (): JSX.Element => {
  const menuItems = useAppSelector(selectMenuItems);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getBladeMenuItems = async () => {
      await dispatch(fetchBladeMenu());
    }
    getBladeMenuItems();
  }, []);

  useEffect(() => {
    const getBladeMenuFavorites = async () => {
      await dispatch(fetchUserPreference('favorite'));
    }
    getBladeMenuFavorites();
  }, []);

  const returnReports = (items: BladeMenuItem[]) => {
    return items.filter(item => item.urlType === 'report')
  }

  const renderMenuLink = (targetName: string, iconElement: JSX.Element) => {
    const linkTarget = menuItems.filter((item) => item.urlType === targetName);

    return linkTarget.map((item) => {
      return (
        <BladeLink
          key={item.id}
          label={item.displayName}
          Icon={iconElement}
          route={item.url}
        />
      );
    });
  }


  const reportMenuLayout = () => returnReports(menuItems).map((item) => {
    return (
      <BladeItem
        key={item.id}
        item={item}
      />
    )
  });

  return (
    <Box data-testid="blade-menu" className={styles.blade_menu}>
      <BladeLink
        label={"Home"}
        Icon={<HomeIcon className={styles.icon} />}
        route={"/"}
      />
      {reportMenuLayout()}
      {renderMenuLink("admin", <CogIcon className={styles.icon} />)}
    </Box>
  );
}