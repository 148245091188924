import React from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

const CenteredCircularProgress: React.FC<CircularProgressProps> = (props) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Centering by transforming
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress {...props} sx={{ color: '#00aebd' }} />
    </Box>
  );
};

export default CenteredCircularProgress;
