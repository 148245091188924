import "rsuite/dist/rsuite.min.css";
import { Dropdown, ButtonToolbar } from "rsuite";
import { fetchEmbedExportConfig } from "../embeddedContentExport/embeddedContentExportSlice";
import { ContentInit } from "common/Types/EmbeddedContentTypes";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { BladeMenuItem } from "common/Types/BladeMenuTypes";
import { selectAllReportMenuItems } from "../bladeMenu/bladeMenuSlice";
import { useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import "./embeddedContentExport.scss";
import { ReactComponent as CircleIcon } from "common/Styles/svg_icons/circle-x_24px.svg";
import { ReactComponent as TriangleIcon } from "common/Styles/svg_icons/triangle-exclamation_24px.svg";

let visualresults: any;

let visibleerror = false;

let visualPageName: any;

let visualPageDescription: any;

let packageType: any;

let bookmarkState: any;

export const getVisualsData = (
  Visuals: ArrayConstructor,
  BookMarkState: ArrayConstructor
) => {
  bookmarkState = BookMarkState;
  visualresults = Visuals;
  return visualresults;
};

const ExportPowerBiReport = () => {
  const [visualSubmenuoptions, setVisualSubmenuoptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  const exportOptions = [
    {
      label: "Export Report Package",
      value: "exportReportPackage",
      role: "report",
    },
    {
      label: "Export Entire Page",
      value: "exportEntirePage",
      role: "page",
    },
    //Remove Visual Export Option as per this user story:107670
    // {
    //   label: "Export Individual Visual",
    //   value: "exportIndividualVisual",
    //   role: "individual",
    // },
  ];

  const submenuoptions = [
    {
      label: "pptx(Powerpoint)",
      value: "pptx",
    },
    {
      label: "PDF",
      value: "pdf",
    },
    {
      label: "PNG",
      value: "png",
    },
  ];

  const updatedVisualSubmenuoptions = [...visualSubmenuoptions];

  let formatType: any;

  let activePageName: any;

  const dispatch = useAppDispatch();

  const defaultInit = {
    workspaceId: "",
    reportId: "",
  };

  const ShowCircularProgress = () => {
    setVisible(true); //Show spinner.
  };

  const HideCircularProgress = () => {
    setVisible(false); //Hide spinner.
  };

  const ShowErrorCircularProgress = () => {
    //Show error spinner.
    visibleerror = true;
    setVisible(true);
    setTimeout(() => {
      HideErrorCircularProgress();
    }, 5000);
  };

  const HideErrorCircularProgress = () => {
    visibleerror = false;
    setVisible(false); //Hide error spinner.
  };

  const handleClose = () => {
    visibleerror = false;
    setVisible(false); //Hide error spinner.
  };

  const [visible, setVisible] = useState(false);

  const allMenuOptions = useAppSelector(selectAllReportMenuItems);

  let [searchParams] = useSearchParams();

  let activePage = searchParams.get("contentPage");

  activePageName = activePage;

  let Visualsdata: any;

  function getMatchingPageName(contentName: string | null): string | undefined {
    if (!contentName) return;
    const menu = getMatchingMenuItem(contentName);
    const reportPage = menu?.pages?.find((page) =>
      Object.values(page).includes(contentName)
    );
    return reportPage?.displayName;
  }

  const getMatchingMenuItem = (
    contentName: string | null
  ): BladeMenuItem | undefined => {
    if (!contentName) return;
    const result = allMenuOptions.find((menuItem) =>
      menuItem.pages?.find((page) => Object.values(page).includes(contentName))
    );
    return result;
  };

  const getMatchingReportInit = (contentName: string | null): ContentInit => {
    if (!contentName) return defaultInit;
    const result = getMatchingMenuItem(contentName);
    return result?.configuration ?? defaultInit;
  };

  let menuItem = getMatchingMenuItem(activePage);

  let reportInit = getMatchingReportInit(activePage);

  let pageName = getMatchingPageName(activePage);

  const onMenuSelected = async (eventKey: any) => {
    if (eventKey === "exportReportPackage-0") {
      packageType = "Export-Report-Package";
    }
    if (eventKey === "exportEntirePage-1") {
      packageType = "Export-Entire-Page";
    }
    if (eventKey === "exportIndividualVisual-2") {
      packageType = "Export-Individual-Visual";
      Visualsdata = getVisualsModifiedData(visualresults);
      console.log(Visualsdata);
    }
  };

  const onSubVisualMenuSelect = async (eventKey: any) => {
    visualPageName = eventKey.split("-")[0];
    visualPageDescription = eventKey.split("-")[1];
  };

  const onSubMenuSelect = async (eventKey: any) => {
    visibleerror = false;
    if (eventKey === "exportReportPackage-pptx-0") {
      formatType = "PPTX";
    }
    if (eventKey === "exportReportPackage-pdf-1") {
      formatType = "PDF";
    }
    if (eventKey === "exportReportPackage-png-2") {
      formatType = "PNG";
    }

    if (eventKey === "exportEntirePage-pptx-0") {
      formatType = "PPTX";
    }
    if (eventKey === "exportEntirePage-pdf-1") {
      formatType = "PDF";
    }
    if (eventKey === "exportEntirePage-png-2") {
      formatType = "PNG";
    }

    if (eventKey === "exportIndividualVisual-pptx-0") {
      formatType = "PPTX";
    }
    if (eventKey === "exportIndividualVisual-pdf-1") {
      formatType = "PDF";
    }
    if (eventKey === "exportIndividualVisual-png-2") {
      formatType = "PNG";
    }

    ShowCircularProgress();

    try {
      await dispatch(
        fetchEmbedExportConfig({
          requestedContent: {
            type: "report",
            ...reportInit,
          },
          reportPackName: menuItem?.displayName ?? "",
          packageType: packageType,
          formatType: formatType,
          activePageName: activePageName,
          pageName: pageName!,
          visualPageName: visualPageName,
          visualPageDescription: visualPageDescription,
          bookmarkState: bookmarkState,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          visibleerror = true;
          HideCircularProgress();
          ShowErrorCircularProgress();
        } else {
          visibleerror = false;
          HideCircularProgress();
        }
      });
    } catch (err) {
      visibleerror = true;
      HideCircularProgress();
      ShowErrorCircularProgress();
    }
  };

  const getVisualsModifiedData = (Visuals: ArrayConstructor) => {
    let visualsnewarray = [];
    let resultArray = [];
    if (visualresults != null) {
      for (let i = 0; i < visualresults.length; i++) {
        if (visualresults[i].type === "tableEx") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "donutChart") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "lineChart") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "lineStackedColumnComboChart") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "slicer") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "clusteredColumnChart") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "columnChart") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "card") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "funnel") {
          visualsnewarray.push(visualresults[i]);
        }
        if (visualresults[i].type === "barChart") {
          visualsnewarray.push(visualresults[i]);
        }
      }

      for (let k = 0; k < visualsnewarray.length; k++) {
        resultArray.push({
          label: visualsnewarray[k].title,
          value: visualsnewarray[k].name,
        });
      }
    }
    setVisualSubmenuoptions(resultArray);
    return resultArray;
  };

  const EmbeddedCircularProgress = () => {
    return (
      <div>
        {visible === true && visibleerror === false && (
          <div className="circularProgress">
            <CircularProgress color="primary" size={30} thickness={3.6} />
          </div>
        )}

        {visible === true && visibleerror === true && (
          <div className="circularProgress">
            <TriangleIcon />
            <div className="errorInfo">
              Data could not be exported. Please try again later.
            </div>
            <CircleIcon onClick={handleClose} className="circleIcon" />
          </div>
        )}
      </div>
    );
  };

  return (
    <ButtonToolbar className="embedded-content-export">
      <Dropdown
        title="DOWNLOAD"
        style={{ marginRight: "40px" }}
        id="embedded-content-export-download"
        className="embedded-content-export-download"
      >
        {exportOptions.map((option, index) => {
          return (
            <Dropdown.Item
              key={option.value}
              eventKey={`${option.value}-${index}`}
              onSelect={onMenuSelected}
            >
              <Dropdown title={option.label} placement="leftStart">
                {option.value === "exportIndividualVisual"
                  ? updatedVisualSubmenuoptions.map((subvisualOpt, index) => {
                      return (
                        <Dropdown.Item
                          key={`${subvisualOpt.value}-${subvisualOpt.label}-${index}`}
                          eventKey={`${subvisualOpt.value}-${subvisualOpt.label}-${index}`}
                          onSelect={onSubVisualMenuSelect}
                        >
                          <Dropdown
                            title={subvisualOpt.label}
                            placement="leftStart"
                          >
                            {submenuoptions.map((subOpt, index) => {
                              return (
                                <Dropdown.Item
                                  key={`${option.value}-${subOpt.value}-${index}`}
                                  eventKey={`${option.value}-${subOpt.value}-${index}`}
                                  onSelect={onSubMenuSelect}
                                >
                                  {subOpt.label}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown>
                        </Dropdown.Item>
                      );
                    })
                  : submenuoptions.map((subOpt, index) => {
                      return (
                        <Dropdown.Item
                          key={`${option.value}-${subOpt.value}-${index}`}
                          eventKey={`${option.value}-${subOpt.value}-${index}`}
                          onSelect={onSubMenuSelect}
                        >
                          {subOpt.label}
                        </Dropdown.Item>
                      );
                    })}
              </Dropdown>
            </Dropdown.Item>
          );
        })}
      </Dropdown>
      {EmbeddedCircularProgress()}
    </ButtonToolbar>
  );
};

export default ExportPowerBiReport;
