import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import userManager from 'common/Utils/userManager';
import styles from '../appMenu.module.scss';
import configData from 'config.json';

interface AccountMenuProps {
  userName: string;
}

export const AccountMenu = (props: AccountMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = (event: any) => {
    event.preventDefault();
    userManager.signoutRedirect();
    userManager.removeUser();
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: "rgba(245,148,168,.24)",
        height: "35px",
        width: "35px",
        color: "#f595a8",
        fontSize: "14px",
        opacity: .8
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar {...stringAvatar(props.userName)} />
            <span className={styles.avatar_halo}></span>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          top: "-7px",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0px",
            width: "220px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem className={styles.user_menu_item}>
          <Button href={`${configData.GATEWAY_CS_URL}/edit-profile`}>
            Edit Profile
          </Button>
        </MenuItem>
        <MenuItem className={styles.user_menu_item}>
          <Button href={`${configData.GATEWAY_CS_URL}/account/reset`}>
            Change Password
          </Button>
        </MenuItem>
        <Divider sx={{ marginLeft: "40px", marginRight: "40px" }} />
        <MenuItem
          onClick={(event) => logout(event)}
          className={styles.user_menu_item}
        >
          <Button href="#">Logout</Button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default AccountMenu;