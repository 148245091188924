export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export interface dateFormatOptions {
  isWithSeconds?: boolean;
  shortMonth?: boolean;
  internationalFormat?: boolean;
}

export function formatDate (dateString: string, options?: dateFormatOptions) {
  if (!dateString) {
    return dateString;
  }
  let date = new Date(dateString);
  let day = date.getDate().toString();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear().toString();
  let time = formatAMPM(date);

  const rtnMonth = options?.shortMonth ? month.substring(0, 3) : month; 

  const calDate = options?.internationalFormat ? `${day} ${rtnMonth}` : `${rtnMonth} ${day}`;

  let returnValue = `${calDate} ${year}, ${time}`;

  return returnValue;
}

export function formatAMPM(dateObj: Date, options?: dateFormatOptions) {
  let hours = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  let stringMinutes = minutes < 10 ? '0' + minutes : minutes;

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  let strTime = '';

  if (options?.isWithSeconds) {
    let seconds = dateObj.getSeconds();
    let secondsString = seconds < 10 ? '0' + seconds : seconds;

    strTime = `${hours}:${stringMinutes}:${secondsString} ${ampm}`;
  } else {
    strTime = `${hours}:${stringMinutes} ${ampm}`;
  }

  return strTime;
}