import axios from "axios";
import { FetchEmbedExportConfigDTO } from "common/Types/EmbeddedContentTypes";
import configData from "config.json";
import FileSaver from "file-saver";

export const embedConfigExportRequest = async (
  dto: FetchEmbedExportConfigDTO
) => {
  try {
    return await axios
      .post(
        `${configData.ENDPOINT_ALX_APIBASEURL}/download-powerbi-report/
    ${dto.requestedContent.type}/${dto.reportPackName}/${dto.packageType}/${dto.formatType}/${dto.activePageName}/${dto.visualPageName}/${dto.pageName}/${dto.visualPageDescription}/${dto.bookmarkState}`,
        {
          ...dto.requestedContent,
        },
        {
          params: {
            code: configData.ENDPOINT_ALX_REQUEST_CODE,
          },
        }
      )
      .then((response) => {
        const data = response.data; // assume you have the data here
        const arrayBuffer = base64ToArrayBuffer(data);
        const date = new Date();
        const formattedDate = date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
          .replace(/ /g, "-");
        if (dto.formatType !== "PNG") {
          let pageName : string;
          if (dto.packageType === "Export-Entire-Page") {
            pageName = dto.pageName;
          }
          else if (dto.packageType === "Export-Report-Package") {
            pageName = dto.reportPackName;
          }
          else{
            pageName = dto.pageName+"_"+dto.visualPageDescription;
          }
          createAndDownloadBlobFile(
            arrayBuffer,
            pageName + "_" + formattedDate,
            dto.formatType
          );
        } else {
          if (dto.packageType === "Export-Entire-Page") {
            createAndDownloadBlobFile(
              arrayBuffer,
              dto.pageName + "_" + formattedDate,
              dto.formatType
            );
          } 
          else if(dto.packageType === "Export-Individual-Visual"){
            createAndDownloadBlobFile(
              arrayBuffer,
              dto.pageName+"_"+ dto.visualPageDescription + "_" + formattedDate,
              dto.formatType
            );
          }
          else {
            const arrayBuffer = base64ToArrayBuffer(data);
            const blob = new Blob([arrayBuffer], {
              type: "application/octet-stream",
            });
            const fileName = dto.reportPackName + "_" + formattedDate + ".zip";
            FileSaver(blob, fileName);
          }
        }
        return response.data;
      })
      .catch((err) => {
        const error = err as Error;
        throw new Error(
          `The requested download powerbi report was not returned. Error: ${error.message}`
        );
      });
  } catch (err) {
    const error = err as Error;
    throw new Error(
      `The request to the download powerbi configuration server failed with the following message: ${error.message}`
    );
  }
};

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(
  body: BlobPart,
  filename: string,
  extension: string
) {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;

  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function ExportFolderTobytes(filezip: string) {
  const bytes = new Uint8Array(filezip.length);
  for (var i = 0; i < filezip.length; i++) {
    bytes[i] = filezip.charCodeAt(i);
  }
  return bytes;
}
