import { RootState } from 'state/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AsyncStatus } from 'common/Types/StateReferenceTypes';
import { setupUserSessionRequest } from 'gatewayApi';
import { UserState } from 'redux-oidc';
import { appTelemetry } from 'services/TelemetryService';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from 'common/Utils/telemetryUtil';
import configData from 'config.json';
import { User } from 'oidc-client';

export interface GatewayState {
  sessionId: string;
  sessionSetup: boolean;
  status: AsyncStatus;
  mockUser?: User;
}

const initialState: GatewayState = {
  sessionId: uuidv4(),
  sessionSetup: false,
  status: 'idle'
};

export const fetchUserSession = createAsyncThunk(
  'gateway/userSessionRequest',
  async (_, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/bootstrap-user`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await setupUserSessionRequest().then((res) => {
      appTelemetry( apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });
    
    return response;
  }
);

export const gatewaySlice = createSlice({
  name: 'gateway',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserSession.fulfilled, (state) => {
        state.status = 'idle';
        state.sessionSetup = true;
        state.sessionId = uuidv4();
      })
      .addCase(fetchUserSession.rejected, (state) => {
        state.status = 'failed';
      })
  }
})

export const selectOidcUser = (state: RootState) => state.oidc.user;
export const selectSessionId = (state: RootState) => state.gateway.sessionId;
export const selectUserSessionState = (state: RootState) => state.gateway.sessionSetup;
export const selectOidc = (state: RootState) => state.oidc;
export const selectGateway = (state: RootState) => state.gateway;

export default gatewaySlice.reducer;