import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tooltip from '@mui/material/Tooltip';
import { useSearchParams } from "react-router-dom";
import {
  BladeMenuItemOption,
  BladeMenuItem,
} from "common/Types/BladeMenuTypes";
import styles from "../bladeItem.module.scss";
import { FavoriteControl } from "../../../favoriteControl/FavoriteControl";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useAppSelector } from "state/hooks";
import { selectOidcUser, selectSessionId } from "gatewaySlice";
import configData from 'config.json';
import React from "react";

export type BladeMenuOptionProps = {
  option: BladeMenuItemOption;
  item: BladeMenuItem;
};

export const BladeOption = ({ item, option }: BladeMenuOptionProps): JSX.Element => {
  const { urlType, url } = item;
  let [searchParams] = useSearchParams();
  let activePageName = searchParams.get("contentPage") ?? "";
  const appInsights = useAppInsightsContext();
  const user = useAppSelector(selectOidcUser);
  const sessionId = useAppSelector(selectSessionId);

  const trackOptionClick = useTrackEvent(appInsights, "On Click on App Menu", {});

  let navigate = useNavigate();

  const handleOptionClick = () => {
    trackOptionClick({
      message: `Report: ${item.name}} Page: ${option.displayName}`,
      userName: user?.profile['name'],
      sessionId,
      applicattionName: configData.ENDPOINT_ALX_APP_NAME,
      environment: configData.ENDPOINT_ALX_APP_ENV,
      menuName: option.displayName
    })
    navigate(`${url}?contentPage=${option.name}`);
  }

  const returnLinkStyle = () => {
    return option.name === activePageName ? styles.selected_blade : "";
  }

  return (

    <Box
      key={`${option.displayName}_${option.order}_${urlType}`}
      data-testid="blade-menu-list-item-option"
      className={`${styles.blade} ${styles.blade_option} ${returnLinkStyle()}`}
      onClick={() => handleOptionClick()}
    >
      <FavoriteControl preferredItem={item} keyName={option.name} displayName={option.displayName} location={'Blade Menu'} />
      <Tooltip title={option.description ?? 'Description Text'} placement={"right"}>
        <span>
          {option.displayName}
        </span>
      </Tooltip>
    </Box>

  );
};
