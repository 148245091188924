import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ReportsCard } from './reportsCard/ReportsCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchSponsors, selectSponsorCards } from './reportManagementSlice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { ReactComponent as Sort } from 'common/Styles/svg_icons/Icons_Filled_exchange-alt_24px.svg';
import { ReactComponent as Search } from 'common/Styles/svg_icons/Icons_Filled_search_24px.svg';
import IconButton from '@mui/material/IconButton/IconButton';
import TextField from '@mui/material/TextField/TextField';
import styles from './reportManagement.module.scss';
import { SponsorCard } from 'common/Types/ReportMgmtTypes';
import { Typography } from '@mui/material';
import CenteredCircularProgress from 'components/commonComponent/CenteredCircularProgress';

type OrderVal = 1 | -1;

export const ReportManagement = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const sponsors = useAppSelector(selectSponsorCards);
  const [searchVal, setSearchVal] = useState<string>('');
  const [sortVal, setSortVal] = useState<number>(0);

  useEffect(() => {
    if (!Object.keys(sponsors).length) dispatch(fetchSponsors());
  }, [])

  const sortTitle = (): string => {
    switch (sortVal) {
      case (1):
        return 'A-Z';
      case (2):
        return 'Z-A';
      default:
        return '';
    }
  }

  const alphaNumSort = (order: OrderVal, array: SponsorCard[]) => array.sort((x, y) => {
    const sponsorX = x.cardSponsor.name.toLocaleLowerCase();
    const sponsorY = y.cardSponsor.name.toLocaleLowerCase();

    let res = 0;
    if (sponsorX > sponsorY) {
      res = 1;
    } else if (sponsorX < sponsorY) {
      res = -1;
    }
    return res * order;
  });

  const applySearchTerm = (array: SponsorCard[], searchTerm: string): SponsorCard[] =>
    array.filter(sponsor =>
      sponsor.cardSponsor.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


  const sortedSponsors = useMemo(() => {
    const sponsorsArr = applySearchTerm(Object.values(sponsors), searchVal);
    switch (sortVal) {
      case 1:
        return alphaNumSort(1, [...sponsorsArr]);
      case 2:
        return alphaNumSort(-1, [...sponsorsArr]);
      default:
        return sponsorsArr;
    }
  }, [sponsors, searchVal, sortVal]);

  const incrementFilter = () => {
    sortVal < 2 ? setSortVal(sortVal + 1) : setSortVal(0);
  }

  const renderSponsorCards = useCallback(() => sortedSponsors.map((sponsorCard) => (
    <ReportsCard card={sponsorCard} key={sponsorCard.cardSponsor.id} />
  )), [sortedSponsors]);

  return (
    <Box>
      <Box className={styles.stack_control}>
        <TextField
          placeholder="Search Sponsor Name"
          value={searchVal}
          className={styles.sponsor_search}
          InputProps={{
            startAdornment: <Search className={styles.sponsor_search} />,
          }}
          onChange={(event) => setSearchVal(event.target.value)}
          variant="standard"
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography className={styles.sort_title}>{sortTitle()}</Typography>
          <IconButton onClick={incrementFilter}>
            <Sort className={styles.sort_button} title="Sort alphabetically" />
          </IconButton>
        </Box>
      </Box>
      {Object.values(sponsors).length ? (
        <Stack spacing={2}>{renderSponsorCards()}</Stack>
      ) : (
        <CenteredCircularProgress />
      )}
    </Box>
  );
}