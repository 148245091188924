import Box from "@mui/material/Box";
import { selectOidcUser } from "gatewaySlice";
import { useAppSelector } from "state/hooks";

const UserInfo = (): JSX.Element => {
  const user = useAppSelector(selectOidcUser);

  if (!!user) {
    return (
      <Box>
        <Box className="container">
          <h2>User information</h2>
          <table className="table">
            <tbody>
              <tr>
                <td>token_type</td>
                <td>{user.token_type}</td>
              </tr>
              <tr>
                <td>access_token</td>
                <td>{user.access_token}</td>
              </tr>
              <tr>
                <td>expires_at</td>
                <td>{user.expires_at}</td>
              </tr>
              <tr>
                <td>scope</td>
                <td>{user.scope}</td>
              </tr>
              {Object.keys(user.profile).map((key) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{user.profile[key]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    );
  } else {
    return <Box>No Active Valid User</Box>;
  }
};

export default UserInfo;
