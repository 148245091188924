import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from 'state/store';
import { AsyncStatus } from "common/Types/StateReferenceTypes";
import { AppMenuItem } from 'common/Types/AppMenuTypes';
import { appMenuItemsRequest } from './appMenuApi';
import { UserState } from "redux-oidc";
import { GatewayState } from "gatewaySlice";
import configData from 'config.json';
import { appTelemetry } from "services/TelemetryService";
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from "common/Utils/telemetryUtil";

export interface AppMenuState {
  status: AsyncStatus;
  menuItems: AppMenuItem[] | [];
}

const initialState: AppMenuState = {
  menuItems: [],
  status: 'idle'
};

export const fetchAppMenu = createAsyncThunk(
  'appMenu/appMenuRequest',
  async (_, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.GATEWAY_CS_URL}/api/Menus/CentralServices_MainNav`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });
    
    const response = await appMenuItemsRequest().then((res) => {
      appTelemetry( apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });
    return response;
  }
);

export const appMenuSlice = createSlice({
  name: 'appMenu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppMenu.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAppMenu.fulfilled, (state, action) => {
        state.status = 'idle';
        state.menuItems = action.payload;
      })
      .addCase(fetchAppMenu.rejected, (state) => {
        state.status = 'failed';
      })
  }
});

export const selectMenuReady = (state: RootState) => state.appMenu.menuItems.length > 0;
export const selectMenuItems = (state: RootState) => state.appMenu.menuItems;

export default appMenuSlice.reducer;