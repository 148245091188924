import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { FavoritesDeck } from './favoritesDeck/FavoritesDeck';
import { FrequencyTable } from './frequencyTable/FrequencyTable';
import styles from "./overview.module.scss";
import { useEffect } from 'react';
import configData from 'config.json';
import { selectOidcUser, selectSessionId } from 'gatewaySlice';
import { useAppSelector } from 'state/hooks';

export const Overview = (): JSX.Element => {
  const user = useAppSelector(selectOidcUser);
  const sessionId = useAppSelector(selectSessionId);
  const appInsights = useAppInsightsContext();
  const trackHomePageNavigation = useTrackEvent(appInsights, "On Home Load", {});

  useEffect(() => {
    trackHomePageNavigation({ 
      message: 'User navigated to home page',
      username: user?.profile['name'],
      sessionId,
      applicationName: configData.ENDPOINT_ALX_APP_NAME,
      environment: configData.ENDPOINT_ALX_APP_ENV
    });
  }, [])

  return (
    <Box>
      {/* Favorites section */}
      <Box className={styles.overview_section}>
        <Typography className={styles.overview_section_title}>
          Favorites
        </Typography>
        <FavoritesDeck />
      </Box>

      {/* Frequent pages */}
      <Box className={styles.overview_section}>
        <Typography className={styles.overview_section_title}>
          Frequently Opened Analytics Pages
        </Typography>
        <FrequencyTable />
      </Box>
    </Box>
  );
}