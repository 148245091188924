import React from 'react';
import { Box, Button} from '@mui/material';
import { GridFooterContainer, GridPagination } from '@mui/x-data-grid';
interface CustomFooterProps {
  onSave: () => void;
  saveDisabled: boolean;
}

const CustomFooter: React.FC<CustomFooterProps> = ({ onSave, saveDisabled }) => {
  return (
    <GridFooterContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '8px 16px',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <GridPagination />
        <Button variant="contained"  onClick={onSave}  disabled={saveDisabled} sx={{
            color: '#00aebd',
            backgroundColor: 'white',
            borderColor: '#00aebd',
            '&:hover': {
              backgroundColor: '#e0f7fa', // Lighten the hover background color
            },
            '&.Mui-disabled': {
              color: '#c0c0c0', // Adjust color when disabled
              backgroundColor: '#f0f0f0', // Adjust background when disabled
            },
          }}>
          Save
        </Button>
      </Box>
    </GridFooterContainer>
  );
};

export default CustomFooter;
