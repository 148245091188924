import axios from 'axios';
import configData from 'config.json';

export const setupUserSessionRequest = async () => {    
    try {
        return axios.get(`${configData.ENDPOINT_ALX_APIBASEURL}/bootstrap-user`).then((response) => {
            return response.data;
        }).catch((err) => {
            const error = (err as Error);
            throw new Error(`The setup user session request was not returned. Error: ${error.message}`);
        });
    } catch (err) {
        const error = (err as Error);
        throw new Error(`The request to the setup user session request server failed with the following message: ${error.message}`);
    }
}