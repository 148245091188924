import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { setUserPreference } from '../../preferences/preferencesSlice';
import { PreferredItem, UserPreferenceDTO } from 'common/Types/UserPreferenceTypes';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { ReactComponent as StarIcon } from "common/Styles/svg_icons/Icons_Filled_star_24px.svg";
import styles from '../favoritesDeck.module.scss';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { selectOidcUser, selectSessionId } from 'gatewaySlice';
import configData from 'config.json';
import Tooltip,
{ tooltipClasses, TooltipProps }
  from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface FavoriteCardProps {
  favorite: PreferredItem,
  icon: JSX.Element,
  avatar: string
}

export const FavoriteCard = ({favorite, icon, avatar}: FavoriteCardProps): JSX.Element => {
  const user = useAppSelector(selectOidcUser);
  const sessionId = useAppSelector(selectSessionId);
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const trackFavoriteRemove = useTrackEvent(appInsights, 'On Click of Favorite Link on Home Page', {});

  const preferenceDto: UserPreferenceDTO = {
    preferenceType: 'favorite',
    preference: {
      menuId: favorite.menuId,
      pageName: favorite.pageName,
      isActive: false,
      displayPageName: favorite.displayName,
      reportPack: favorite.menuName
    }
  }

  const removeFavorite = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setUserPreference(preferenceDto));
    trackFavoriteRemove({
      message: `User favorited or unfavorited report: ${favorite.pageName} Page: ${favorite.displayName}`,
      userName: user?.profile["name"],
      sessionId: sessionId,
      applicationName: configData.ENDPOINT_ALX_APP_NAME,
      environment: configData.ENDPOINT_ALX_APP_ENV,
      reportName: favorite.pageName,
      menuId: favorite.menuId,
      pageName: favorite.displayName,
    });
  }

  const tooltipFill = "#536163";

  const CustTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement={"right"} arrow enterDelay={650} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: tooltipFill,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: tooltipFill,
    },
  }));

  // const displayNameClass = favorite.displayName.length>=25?`${styles.card_content_name} ${styles.card_display_name}`: `${styles.card_content_name}`
  
  return (
    <Card 
      className={styles.card}
      onClick={() =>
        navigate(`/${favorite.urlType}?contentPage=${favorite.pageName}`)
      }
    >
      <CardContent className={styles.card_content_height} sx={{ display: "flex", flexDirection: "column" }} >
        <Box className={styles.card_label_container} >
          <Box sx={{ display: "flex", alignItems: "center", minHeight:"100px", marginRight:"5px"}}>
            <Avatar className={avatar}>
            <Box sx={{marginTop:"-2px"}}>{icon}</Box>
            </Avatar>
          </Box>
          <Box className={styles.card_label} sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", minHeight:"50px"}}>
              <Box sx={{flexGrow:"1",alignSelf: "end"}}>
                <CustTooltip title={favorite.displayName} >
                  <Typography sx={{alignSelf:"end"}}> 
                    {favorite.displayName}
                  </Typography>
                </CustTooltip>
              </Box>
              <Box onClick={(event) => removeFavorite(event)}                >
                <StarIcon className={styles.star_badge} />
              </Box>
            </Box>
            <Divider />
            <CustTooltip title={favorite.description ?? 'Description text'}>
              <Typography className={styles.card_description} >
                {favorite.description ?? 'Description text'}
              </Typography>
            </CustTooltip>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}