import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import gatewayReducer from 'gatewaySlice';
import { reducer as oidcReducer, loadUser } from 'redux-oidc';
import bladeMenuReducer from 'components/bladeMenu/bladeMenuSlice';
import appMenuReducer from 'components/appMenu/appMenuSlice';
import embeddedContentFrameReducer from 'components/embeddedContentFrame/embeddedContentFrameSlice';
import userPreferencesReducer from 'components/overview/preferences/preferencesSlice';
import reportManagementReducer from 'components/reportManagement/reportManagementSlice';
import userManager from 'common/Utils/userManager';
import logger from 'redux-logger';

export const reducers = {
  gateway: gatewayReducer,
  oidc: oidcReducer,
  bladeMenu: bladeMenuReducer,
  appMenu: appMenuReducer,
  embeddedContentFrame: embeddedContentFrameReducer,
  userPreferences: userPreferencesReducer,
  reportManagement: reportManagementReducer
}

userManager.events.addSilentRenewError(function (error) {
  console.error('error while renewing the access token', error);
});


export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV !== 'production') {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['redux-oidc/USER_FOUND'],
          ignoredPaths: ['oidc.user']
        }
      }).concat(logger);
    } else {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['redux-oidc/USER_FOUND'],
          ignoredPaths: ['oidc.user']
        }
      })
    }
  }
});

loadUser(store, userManager);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
