import React from 'react';
import ReactDOM from 'react-dom';
import RootRouter from 'Gateway';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';
import { reactPlugin } from 'services/TelemetryService';
import { store } from 'state/store';
import 'index.css';

ReactDOM.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
        <Provider store={store}>
          <Router>
            <RootRouter />
          </Router>
        </Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
