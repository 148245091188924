import { CallbackComponent } from 'redux-oidc';
import { User } from 'oidc-client';
import { useNavigate } from 'react-router-dom';
import userManager from 'common/Utils/userManager';

const CallbackPage = () => {
  const navigate = useNavigate();

  const success = (user: User) => {
    // get the user's previous location, passed during signinRedirect()
    var redirectPath = user.state.path as string;
    navigate(redirectPath);
  };

  const error = (error: Error) => {
    console.error(`oidc callback error: ${error.message}`);
    navigate('/');
  }

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={success}
      errorCallback={error}
    >
      <div>Redirecting...</div>
    </ CallbackComponent>
  )
}

export default CallbackPage;