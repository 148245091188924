import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';
const configData = require('config.json');

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
      connectionString: configData.ENDPOINT_ALX_APPLICATION_INSIGHTS_CONNECTION_STRING,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
});
appInsights.loadAppInsights();

export const appTelemetry = (name: string, message: string, oidc: UserState, gateway: GatewayState, customProps: object) => appInsights.trackEvent({
  name
}, {
  message,
  userName: oidc.user?.profile['name'],
  sessionId: gateway.sessionId,
  applicationName: configData.ENDPOINT_ALX_APP_NAME,
  environment: configData.ENDPOINT_ALX_APP_ENV,
  ...customProps
});

export { reactPlugin, appInsights };