import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";

interface TimedClientDialogProps {
  //the number of seconds to count down from.
  time: number;
  title: string;
  dialog: string;
  eventDescription: string;
  rejectText: string;
  acceptText: string;
  acceptAction: Function;
  rejectAction: Function;
}

export const TimedClientDialog = ({
  time,
  title,
  dialog,
  rejectText,
  acceptText,
  acceptAction,
  rejectAction,
}: TimedClientDialogProps) => {
  const defaultColor = "#00A4B3";
  const radius = "19px";
  const [tMinus, setTMinus] = useState<number>(time);

  const handleAccept = () => {
    clearTimeout(timer);
    acceptAction();
  };

  const handleReject = () => {
    clearTimeout(timer);
    rejectAction();
  };

  const countDown = () => {
    const count = tMinus - 1;

    if (count > 0) {
      setTMinus(count);
    } else {
      clearTimeout(timer);
      handleReject();
    }
  };

  const timer = setTimeout(countDown, 1000);

  const theme = createTheme({
    palette: {
      primary: {
        main: defaultColor,
      },
      secondary: {
        main: defaultColor,
      },
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Dialog open={true} onClose={handleAccept}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`In ${tMinus} seconds ${dialog}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={theme}>
          <Button
            variant="outlined"
            sx={{
              borderRadius: radius,
            }}
            color="secondary"
            onClick={handleReject}
          >
            {rejectText}
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: radius,
            }}
            onClick={handleAccept}
            autoFocus
          >
            {acceptText}
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};

export default TimedClientDialog;
