import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetchEmbedExportConfigDTO } from "common/Types/EmbeddedContentTypes";
import configData from "config.json";
import { UserState } from "redux-oidc";
import { GatewayState } from "gatewaySlice";
import { appTelemetry } from "services/TelemetryService";
import {
  apiReqEvnt,
  apiReqMsg,
  apiResEvnt,
  apiResMsg,
} from "common/Utils/telemetryUtil";
import { embedConfigExportRequest } from "./embeddedContentExportApi";

export const fetchEmbedExportConfig = createAsyncThunk(
  "ExportPowerBiReport/fetchEmbedExportConfig",
  async (dto: FetchEmbedExportConfigDTO, { getState }) => {
    const { oidc, gateway } = getState() as {
      oidc: UserState;
      gateway: GatewayState;
    };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/download-powerbi-report/
      ${dto.requestedContent.type}/${dto.reportPackName}/${dto.packageType}/${dto.formatType}/${dto.activePageName}/${dto.visualPageName}${dto.pageName}/${dto.visualPageDescription}/${dto.bookmarkState}`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url,
    });

    const response = await embedConfigExportRequest(dto).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url,
      });

      return res;
    });

    return response;
  }
);
