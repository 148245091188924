import axios from 'axios';
import { Sponsor, SponsorCard, MgmtMap, SponsorAppRowModel, SponsorAppReport, ElectionsDTO, SponsorApplication } from '../../common/Types/ReportMgmtTypes';
import configData from 'config.json';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';
import { appTelemetry } from 'services/TelemetryService';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from 'common/Utils/telemetryUtil';


export const sponsorAppsRequest = async (sponsor: Sponsor): Promise<SponsorCard> => {
  const returnAppRowModels = (data: SponsorApplication[]): MgmtMap<SponsorAppRowModel> => {
    return data.reduce((map: MgmtMap<SponsorAppRowModel>, item: SponsorApplication) => {
      const { name, applicationId, sponsorName, reports } = item;
      const generatedId = `${applicationId}_${sponsorName}_${name}`;
      const app: SponsorAppRowModel = {
        id: generatedId,
        name,
        applicationId,
        sponsorName,
        reports,
      };
      map[generatedId] = app;
      return map;
    }, {});
  };

  const returnActiveRows = (apps: SponsorAppRowModel[]): string[] => {
    return apps.reduce((list: string[], app: SponsorAppRowModel) => {
      if (app.reports.some(report => report.selectedRoles !== null)) {
        list.push(app.id);
      }
      return list;
    }, []);
  };

  try {
    const response = await axios.get(`${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${sponsor.id}`, {
      params: {
        code: configData.ENDPOINT_ALX_REQUEST_CODE,
      },
    });

    const preppedApps = returnAppRowModels(response.data as SponsorApplication[]);

    const newCard: SponsorCard = {
      cardSponsor: sponsor,
      cardApps: preppedApps,
      selectedAppRows: returnActiveRows(Object.values(preppedApps)),
      pendingReportPackageElections: {},
    };

    return newCard;
  } catch (err) {
    const error = err as Error;
    throw new Error(`The requested sponsor app was not returned. Error: ${error.message}`);
  }
};

export const fetchSponsorApps = createAsyncThunk(
  'reportManagement/sponsorAppRequest',
  async (sponsor: Sponsor, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState; gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${sponsor.id}`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url,
    });

    const response = await sponsorAppsRequest(sponsor).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url,
      });

      return res;
    });

    return response;
  }
);

export const sponsorsRequest = async (): Promise<MgmtMap<SponsorCard>> => {
  try {
    const returnCardMap = (sponsors: Sponsor[]): MgmtMap<SponsorCard> => {
      const result = sponsors.reduce((map: MgmtMap<SponsorCard>, sponsor: Sponsor) => {
        const card: SponsorCard = {
          cardSponsor: sponsor,
          cardApps: {},
          selectedAppRows: [],
          pendingReportPackageElections: {},
        } 
        map[sponsor.id] = card;
        return map;
      }, {});
      return result;
    }

    return await axios.get(`${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/sponsor`, {
      params: {
        code: configData.ENDPOINT_ALX_REQUEST_CODE
      }
    }).then((response) => {
      return returnCardMap(response.data);
    })
  } catch (err) {
    const error = err as Error;
    throw new Error(`The request for sponsors failed. Error: ${error.message}`);
  }
}

export const saveReportPackageElections = async (electionsDto: ElectionsDTO) => {
  try {
    return await axios.post(`${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${electionsDto.sponsorId}`, 
      electionsDto.electionList,
      {
        params: {
          code: configData.ENDPOINT_ALX_REQUEST_CODE
        }
      }
    ).then((response) => {
      return response.data;
    })
  } catch (err) {
    const error = err as Error;
    throw new Error(`the attempt to save your elections has failed: Error: ${error.message}`);
  }
}