import { createUserManager } from 'redux-oidc';
import { UserManagerSettings } from 'oidc-client';
import configData from 'config.json';

const userManagerConfig: UserManagerSettings = {
  client_id: 'datalyst',
  redirect_uri: `${configData.ENDPOINT_ALX_BASEURL}callback`,
  response_type: 'token id_token',
  scope: "openid profile restApi role",
  authority: configData.GATEWAY_AUTH_URL,
  silent_redirect_uri: `${configData.ENDPOINT_ALX_BASEURL}silentRenew.html`,
  accessTokenExpiringNotificationTime: 30000,
  automaticSilentRenew: false,
  includeIdTokenInSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
  monitorSession: true
};

const userManager = createUserManager(userManagerConfig);
// console.log('userManager querySessionStatus', userManager.querySessionStatus());
// userManager.signinSilent()
// .then(info => console.debug('refresh ok', info))
// .catch(err => console.error('refresh error', err));


// userManager.events.addAccessTokenExpiring(()=>{
//   userManager.signinSilent({scope: userManagerConfig.scope, response_type: userManagerConfig.response_type})
//           .then((user: Oidc.User) =>
//           {
//               // this.handleUser(user);
//               console.log('startSilentRenew', userManager.startSilentRenew())
//           })
//           .catch((error: Error) =>
//           {
//               // Currently as a dirty work around (looks like problem with gluu server. Need to test with other IDP's as well)
//               // in order to get the new issued token I just calling getUser() straight after signinSilent().then() promise failing with frame time out
//               // https://github.com/IdentityModel/oidc-client-js/issues/362
//               // userManager.getUser()
//                   // .then((user: Oidc.User) =>
//                   // {
//                   //     this.handleUser(user);
//                   // });
//                   console.log(' ErrorErrorErrorError',Error)
//           });
// });
// console.log('userManager', userManager);

export default userManager;