import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Location } from 'react-router-dom';

interface LocationState {
  pathname: string;
  search: string;
  hash: string;
  state: any;
  key: string;
}

export function useNavigationPrompt(when: boolean) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showDialog, setShowDialog] = useState(false);
  const [lastLocation, setLastLocation] = useState<LocationState | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = () => {
    setShowDialog(false);
  };

  const confirmNavigation = () => {
    setShowDialog(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname + lastLocation.search + lastLocation.hash);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useEffect(() => {
    if (!when) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (when) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    const handleNavigationAttempt = (nextLocation: LocationState) => {
      if (!confirmedNavigation) {
        setShowDialog(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    };

    const originalPushState = window.history.pushState;

    window.history.pushState = function (state, title, url) {
      const nextLocation = new URL(url || '', window.location.href) as unknown as LocationState;
      const shouldBlock = handleNavigationAttempt(nextLocation);

      if (shouldBlock) {
        originalPushState.call(window.history, state, title, url);
      }
    };

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.history.pushState = originalPushState; // Restore original pushState
    };
  }, [when, confirmedNavigation, location]);

  return { showDialog, confirmNavigation, cancelNavigation };
}
