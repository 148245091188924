import { useState } from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as ArrowDropDownIcon } from 'common/Styles/svg_icons/Icons_Filled_caret-down_small.svg';
import { ReactComponent as ArrowRightIcon } from 'common/Styles/svg_icons/Icons_Filled_caret-right_small.svg';
import { BladeMenuItem, BladeMenuItemOption } from 'common/Types/BladeMenuTypes';
import { BladeOption } from './bladeOption/BladeOption';
import styles from './bladeItem.module.scss';
import Tooltip from '@mui/material/Tooltip';

export type BladeMenuItemProps = {
  item: BladeMenuItem;
}

export const BladeItem = (props: BladeMenuItemProps): JSX.Element => {
  const { item } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOnClick = () => {
    setIsOpen(!isOpen);
  }

  const returnCopiedPages = (pages: BladeMenuItem['pages']) => {
    return !!pages ? [...pages] : []
  }

  const dynamicCarat = () => {
    return isOpen ? <ArrowDropDownIcon className={`${styles.arrow} ${styles.icon}`} /> : <ArrowRightIcon className={`${styles.arrow} ${styles.icon}`} />;
  }

  const bladeMenuItemOptions = (sortedItems: BladeMenuItemOption[] | undefined) => {
    if (isOpen) {
      return (
        <Box className={styles.offset_container}>
          {sortedItems?.map((option: BladeMenuItemOption) => {
            return (
              <BladeOption
                key={`${item.id}_${option.order}`}
                option={option}
                item={item}
              />
            )
          })}
        </Box>
      )
    }
  }

  return (
    <Box
      key={props.item.id}
      data-testid="blade-menu-list"
      className={styles.drawer}
    >
      <Box
        onClick={toggleOnClick}
        data-testid="blade-menu-list-item"
        className={styles.blade}
      >
        {dynamicCarat()}
        <Tooltip title={props.item.description ?? 'Description Text'} placement={"right"}>
          <span>
            {props.item.displayName}
          </span>
        </Tooltip>
      </Box>
      {bladeMenuItemOptions(
        returnCopiedPages(props.item.pages).sort(
          (current, previous) => current.order - previous.order
        )
      )}
    </Box>
  );
};