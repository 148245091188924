import React, { useEffect, useState } from 'react';
import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import { Report, Embed, service } from 'powerbi-client';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from "@mui/material/Box";
import { ContentInit } from 'common/Types/EmbeddedContentTypes';
import styles from '../embeddedContentFrame/embeddedContentFrame.module.scss';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectAllReportMenuItems } from '../bladeMenu/bladeMenuSlice';
import { UserPreferenceDTO } from 'common/Types/UserPreferenceTypes';
import { BladeMenuItem } from 'common/Types/BladeMenuTypes';
import { recordUserPreference } from '../overview/preferences/preferencesAPI';
import { selectOidcUser, selectSessionId } from 'gatewaySlice';
import configData from 'config.json';
import { apiReqEvnt, apiResEvnt, apiReqMsg, apiResMsg } from 'common/Utils/telemetryUtil';
import { appTelemetry } from 'services/TelemetryService';
import { selectOidc, selectGateway } from 'gatewaySlice';
import { fetchEmbedConfig, selectAsyncStatus, selectEmbedConfig } from '../embeddedContentFrame/embeddedContentFrameSlice';
import { TimedClientDialog } from '../timedClientDialog/TimedClientDialog';
import { getVisualsData } from "../embeddedContentExport/EmbeddedContentExport";
import CenteredCircularProgress from 'components/commonComponent/CenteredCircularProgress';

export const EmbeddedReport = (): JSX.Element => {
  let interval: NodeJS.Timer;
  let navigate = useNavigate();
  let Visuals: any;

  const dispatch = useAppDispatch();
  const defaultInit = {
    workspaceId: '',
    reportId: ''
  };
  const allMenuOptions = useAppSelector(selectAllReportMenuItems);
  const user = useAppSelector(selectOidcUser);
  const sessionId = useAppSelector(selectSessionId);
  const oidc = useAppSelector(selectOidc);
  const gateway = useAppSelector(selectGateway);
  const embedConfig = useAppSelector(selectEmbedConfig);
  const asyncStatus = useAppSelector(selectAsyncStatus);

  // eslint-disable-next-line
  const [powerbiReport, setReport] = useState<Report>();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  function getMatchingPageName(contentName: string | null): string | undefined {
    if (!contentName) return;
    const menu = getMatchingMenuItem(contentName);
    const reportPage = menu?.pages?.find((page) =>
      Object.values(page).includes(contentName)
    );
    return reportPage?.displayName
  }

  const getMatchingMenuItem = (contentName: string | null): BladeMenuItem | undefined => {
    if (!contentName) return;
    const result = allMenuOptions.find((menuItem) =>
      menuItem.pages?.find((page) =>
        Object.values(page).includes(contentName)
      )
    );

    return result;
  }

  const getMatchingReportInit = (contentName: string | null): ContentInit => {
    if (!contentName) return defaultInit;
    const result = getMatchingMenuItem(contentName);
    return result?.configuration ?? defaultInit;

  }

  let [searchParams] = useSearchParams();
  let activePage = searchParams.get('contentPage');

  let reportInit = getMatchingReportInit(activePage);
  let menuItem = getMatchingMenuItem(activePage);
  let pageName = getMatchingPageName(activePage);

  const appInsights = useAppInsightsContext();
  const trackReportNavigation = useTrackEvent(appInsights, "On Report Page Load", {});

  const preferenceDto: UserPreferenceDTO = {
    preferenceType: "recent",
    preference: {
      menuId: menuItem?.id ?? '',
      pageName: activePage ?? 'N/A',
      reportPack: menuItem?.name ?? '',
      displayPageName: pageName ?? '',
      isActive: true,
    }
  };

  const getEmbeddedReport = (embeddedReport: Embed) => {
    const report = embeddedReport as Report;
    setReport(report);
  }

  useEffect(() => {
    recordUserPreference(preferenceDto);
    const reportName = menuItem?.name ?? 'unavailable';
    const page = pageName ?? 'unavailable';
    powerbiReport?.setPage(activePage ?? 'N/A');
    trackReportNavigation({
      message: `User navigated. Report ${reportName} Page: ${page}`,
      userName: user?.profile["username"] ?? 'unavailable',
      sessionId,
      applicationName: configData.ENDPOINT_ALX_APP_NAME,
      environment: configData.ENDPOINT_ALX_APP_ENV,
      reportName,
      menuId: menuItem?.id ?? 'unavailable',
      pageName: page,
    });
  }, [activePage, reportInit.reportId]);

  const getEmbedConfig = async () => {
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/powerbi-token/'report'/${menuItem?.displayName ?? ""
      }`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url,
    });

    await dispatch(
      fetchEmbedConfig({
        requestedContent: {
          type: "report",
          ...reportInit,
        },
        reportPackName: menuItem?.displayName ?? "",
      })
    ).then((res) => {
      interval = setInterval(() => {
        setShowDialog(true);
        clearInterval(interval);
      }, 1000 * 60 * res.payload.minutesToExpiration);     

      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url,
      });
    })
  };

  useEffect(() => {
    getEmbedConfig();
  }, [reportInit.reportId]);

  const eventHandlersMap: Map<string, EventHandler> = new Map([
    [
      "loaded",
      async (event?: service.ICustomEvent<any>, embeddedComponent?: Embed) => {
        const report = embeddedComponent as Report;
        report.getPages().then(async pages => {
          setReport(report);
          if (!activePage) return;
          const page = report?.page(activePage);
          page?.setActive().catch((err) => console.error(`an error occured while setting the active page: ${err?.message ?? "Unknown"}`));
        });
        report.render();
      },
    ],
    [
      "rendered",
      async (event?: service.ICustomEvent<any>, embeddedComponent?: Embed) => {
        const report = embeddedComponent as Report;
        let BookMarkState: any;
        let pages = await report.getPages();
        let capturedBookmark  = await report.bookmarksManager.capture();
        BookMarkState = capturedBookmark.state;
        let activePage = pages.filter(function (page) {
          return page.isActive
        })[0];
        Visuals = await activePage.getVisuals();
        getVisualsData(Visuals,BookMarkState.split("/"));
      },
    ],
    [
      "error",
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(
            `an error has occured while processing the report:${event.detail}`
          );
        }
      },
    ],
  ]);

  const embeddedReportContainer = () => {
    if (!embedConfig.accessToken || asyncStatus === 'loading') {
      return <CenteredCircularProgress />
    }
    
    return (
      <PowerBIEmbed
        embedConfig={embedConfig}
        eventHandlers={eventHandlersMap}
        getEmbeddedComponent={getEmbeddedReport}
        cssClassName={styles.embedded_content_target}
        phasedEmbedding={true}        
      />
    )
  }

  const handleNavigateToDashboard = () => {
    clearInterval(interval);
    setShowDialog(false);
    navigate("/");
  }

  const handleRefreshReport = () => {
    getEmbedConfig();
    setShowDialog(false);
  }

  useEffect(() => {
    return () => clearInterval(interval);
  })
  
  return (
    <Box
      role="document"
      aria-label="report container"
      className={styles.powerbi_embedded_content_container}
    >
      {showDialog &&
        <TimedClientDialog
          time={30}
          title={"Report Access Token Expiring"}
          dialog={"If you'd like to continue, confirm you'd like you refresh the report. Otherwise, confirm you'd like to return to the dashboard."}
          eventDescription={"the report access token will expire."}
          acceptText={"Refresh Report"}
          rejectText={"Return to Dashboard"}
          acceptAction={handleRefreshReport}
          rejectAction={handleNavigateToDashboard}
        />}
      {embeddedReportContainer()}
    </Box>
  );
}
