import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { recordUserPreference, requestUserPreference } from './preferencesAPI';
import { RootState } from 'state/store';
import { AsyncStatus } from 'common/Types/StateReferenceTypes';
import { UserPreferenceDTO, PrefType, PreferredItem } from 'common/Types/UserPreferenceTypes'
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';
import { appTelemetry } from 'services/TelemetryService';
import configData from 'config.json';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from 'common/Utils/telemetryUtil';

export interface PreferencesState {
  favorites: PreferredItem[] | [],
  frequencyList: PreferredItem[] | []
  status: AsyncStatus
}

const initialState: PreferencesState = {
  favorites: [],
  frequencyList: [],
  status: 'idle'
}

export const setUserPreference = createAsyncThunk(
  'preferences/setUserPreference',
  async (preferenceDto: UserPreferenceDTO, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/user-preferences/${preferenceDto.preferenceType}`
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    await recordUserPreference(preferenceDto).then(() =>
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      })
    );

    return preferenceDto;
  }
);

export const fetchUserPreference = createAsyncThunk(
  'preferences/fetchUserPreference',
  async (preferenceType: PrefType, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/user-preferences/${preferenceType}`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await requestUserPreference(preferenceType).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });

    return {
      data: response,
      preferenceType
    }
  }
)

export const preferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setUserPreference.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setUserPreference.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.preference.isActive) {
          const { item } = action.payload;
          if (!!item) {
            const newFavorite: PreferredItem = {
              menuName: item.name,
              menuId: item.id,
              url: item.url,
              powerBiConfig: {
                workspaceId: item.configuration?.workspaceId,
                reportId: item.configuration?.reportId,
                enableRls: true
              },
              displayPageName: action.payload.preference.displayPageName,
              pageName: action.payload.preference.pageName,
              urlType: item.urlType,
              displayName: item.pages?.find((report) => report.name === action.payload.preference.pageName)?.displayName ?? '',
              description: item.pages?.find((report) => report.name === action.payload.preference.pageName)?.description ?? '',
            }
            state.favorites = [...state.favorites, newFavorite];
          } else {
            throw new Error('Preferences: The item to generate a new favorite was excluded');
          }
        } else {
          state.favorites = state.favorites.filter(favorite => favorite.pageName !== action.payload.preference.pageName);
        }
      })
      .addCase(setUserPreference.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchUserPreference.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserPreference.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.preferenceType === 'favorite') {
          state.favorites = action.payload.data
        } else if (action.payload.preferenceType === 'frequent') {
          state.frequencyList = action.payload.data
        }
      })
      .addCase(fetchUserPreference.rejected, (state) => {
        state.status = 'failed';
      })
  }
});

export const selectUserFavorites = (state: RootState) => state.userPreferences.favorites;
export const selectFrequencyList = (state: RootState) => state.userPreferences.frequencyList;

export default preferencesSlice.reducer;