import { useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountMenu from './accountMenu/AccountMenu';
import { selectOidcUser } from '../../gatewaySlice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectMenuItems, fetchAppMenu } from './appMenuSlice';
import { formatDate } from 'common/Utils/date';
import { AppMenuItem } from 'common/Types/AppMenuTypes';
import { ReactComponent as EpLogo } from 'common/Styles/svg_icons/Logo_Light.svg';
import configData from 'config.json';
import styles from './appMenu.module.scss';

export const AppMenu = (): JSX.Element => {
  const user = useAppSelector(selectOidcUser);
  const menuItems = useAppSelector(selectMenuItems);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getAppMenuItems = async () => {
      await dispatch(fetchAppMenu());
    };
    getAppMenuItems();
  }, []);

  const getMenuLink = useMemo(() => (link: AppMenuItem): string => {
    return link.isAbsoluteUrl
      ? link.sourceUrl
      : `${configData.GATEWAY_CS_URL}${link.sourceUrl}`;
  }, []);

  const alxUnderline = (menuItemName: string) => {
    if (menuItemName === 'Analytics') {
      return <Box className={styles.analytics_underline}></Box>;
    }
  }

  const getMenus = () => {
    const appMenus: { Order: number; Element: JSX.Element }[] = [];
    menuItems.forEach((element: AppMenuItem, index: number) => {
      if (element.hidden !== true) {
        let link = getMenuLink(element);
        let ele = (
          <li key={`${element.name}_${index}`}>
            <a href={link} className={element.displayName === 'Analytics' ? styles.app_menu_highlight : ''}>
              {element.displayName ?? element.name}
              {alxUnderline(element.displayName)}
            </a>
          </li>
        );
        appMenus.push({
          Order: element?.order === undefined ? 0 : element.order,
          Element: ele,
        });
      }
    });
    let results = appMenus
      .sort((a, b) => (a.Order > b.Order ? 1 : -1))
      .map((a) => a.Element);
    return results;
  };

  const formatLastLoginDate = (dateString: string | undefined) => {
    return dateString ? formatDate(dateString, { shortMonth: true }) : "No Previous Login";
  };

  const renderLastLoginDate = () => {
    const lastLoginDate = user?.profile["endpoint:last_login_datetime"];
    if (lastLoginDate) {
      return (
        <Box className={styles.last_login_container}>
          <Typography>Last Login</Typography>
          <Typography>{formatLastLoginDate(lastLoginDate)} UTC</Typography>
        </Box>
      );
    }
  };

  return (
    <Box className={styles.app_menu} data-testid='app-menu-container'>
      <Box sx={{ display: "flex" }}>
        <EpLogo className={styles.logo} />
        <ul className={styles.app_selection}>{getMenus()}</ul>
      </Box>
      <Toolbar sx={{ display: "flex", height: "60px" }}>
        {renderLastLoginDate()}
        <a href='https://www.endpointclinical.com/contact/' target="_blank" rel="noreferrer">
          <HelpOutlineIcon className={styles.help_icon} />
        </a>
        <AccountMenu
          userName={`${user?.profile["given_name"]} ${user?.profile["family_name"]}`}
        />
      </Toolbar>
    </Box>
  );
};
