import React, { useEffect } from "react";
import userManager from "common/Utils/userManager";

const Login = (): JSX.Element => {
  const login = () => {
    // pass the current path to redirect to the correct page after successfull login
    userManager.signinRedirect({
      data: { path: "/" },
    });
  };

  useEffect(() => {
    login();
  }, []);

  return (
    <React.Fragment></React.Fragment>
  );
};

export default Login;
