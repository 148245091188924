import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { AsyncStatus } from "common/Types/StateReferenceTypes";
import { BladeMenuItem } from 'common/Types/BladeMenuTypes'; 
import { bladeMenuItemsRequest } from './bladeMenuAPI';
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';
import configData from 'config.json';
import { appTelemetry } from 'services/TelemetryService';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from 'common/Utils/telemetryUtil';

export interface BladeMenuState {
  userName?: string;
  status: AsyncStatus;
  menuItems: BladeMenuItem[]; 
}

const initialState: BladeMenuState = {
  userName: undefined,
  menuItems: [],
  status: 'idle'
};

export const fetchBladeMenu = createAsyncThunk(
  'bladeMenu/bladeMenuRequest',
  async (_, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/get-navigation`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await bladeMenuItemsRequest().then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });
    return response;
  }
);

export const bladeMenuSlice = createSlice({
  name: 'bladeMenu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBladeMenu.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBladeMenu.fulfilled, (state, action) => {
        state.status = 'idle';
        state.menuItems = action.payload;
      })
      .addCase(fetchBladeMenu.rejected, (state) => {
        state.status = 'failed';
      })
  }
});

export const selectMenuReady = (state: RootState) => state.bladeMenu.menuItems.length > 0;
export const selectMenuItems = (state: RootState) => state.bladeMenu.menuItems;
export const selectAllReportMenuItems = (state: RootState) => {
  return state.bladeMenu.menuItems.filter(menuItem => menuItem.urlType === 'report');
}
export const selectAllPages = (state:RootState) => {
  return state.bladeMenu.menuItems.map(menuItem => menuItem.pages ?? []).flat(1);;
}

export default bladeMenuSlice.reducer;