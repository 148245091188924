import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  selectFrequencyList,
  fetchUserPreference,
} from '../preferences/preferencesSlice';
import { DataGrid, GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { selectAllPages } from '../../bladeMenu/bladeMenuSlice';
import { returnCompletePreferences } from '../preferences/preferencesAPI';
import styles from './frequencyTable.module.scss';
import Box from '@mui/material/Box';
import { PreferredItem } from 'common/Types/UserPreferenceTypes';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { selectOidcUser, selectSessionId } from 'gatewaySlice';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import configData from 'config.json';

interface RowData extends PreferredItem {
  lastAccessed?: string;
  id: string;
}

export const FrequencyTable = (): JSX.Element => {
  let navigate = useNavigate();

  const user = useAppSelector(selectOidcUser);
  const sessionId = useAppSelector(selectSessionId);
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();
  const frequencyList = useAppSelector(selectFrequencyList);
  const allReportPages = useAppSelector(selectAllPages);
  const completeFrequencyList = returnCompletePreferences(frequencyList, allReportPages);
  const defaultRowCount = 5;
  const trackFrequencyRowClick = useTrackEvent(appInsights, "On Click of Favorite", {});
  const trackSortOrPage = useTrackEvent(appInsights, "On sort or paging", {});

  const [page, setPage] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'last7DaysUsage',
      sort: 'desc'
    }
  ]);

  useEffect(() => {
    handleSortOrPage();
  }, [page, sortModel]);

  const returnFormattedAccessDate = (dateTime: string | undefined): string => {
    if (!!dateTime) {
      const date = new Date(dateTime);
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(date);

      return formattedDate;
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    const getFrequencyList = async () => {
      await dispatch(fetchUserPreference("frequent"));
    };
    getFrequencyList();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'menuName',
      headerName: 'Report Name',
      sortable: true,
      width: 210
    },
    {
      field: 'displayName',
      headerName: 'Page Name',
      sortable: true,
      width: 210
    },
    {
      field: 'lastAccessed',
      headerName: 'Date Last Opened',
      sortable: true,
      width: 210
    },
    {
      field: 'last7DaysUsage',
      headerName: 'Views Past 7 Days',
      sortable: true,
      width: 210
    },
    {
      field: 'isBlinded',
      headerName: 'Blinded',
      sortable: true,
      width: 210
    }
  ]

  const rows = (frequencyData: PreferredItem[]): RowData[] => {
    return frequencyData.map((report) => {
      return {
        ...report,
        lastAccessed: returnFormattedAccessDate(report.eventTimeStamp),
        id: report.powerBiConfig?.reportId ?? uuidv4(),
      };
    })
  }

  const handleSortOrPage = () => {
    trackSortOrPage({
      message: 'User Sorted or Paged in Frequently Opened Analytics Pages table',
      userName: user?.profile["name"],
      sessionId,
      applicationName: configData.ENDPOINT_ALX_APP_NAME,
      environment: configData.ENDPOINT_ALX_APP_ENV
    });
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/${params.row.urlType}?contentPage=${params.row.pageName}`);
    trackFrequencyRowClick({
      message: `User Selected Frequent report: Page: ${params.row.pageName}`,
      userName: user?.profile['name'],
      sessionId,
      applicationName: configData.ENDPOINT_ALX_APP_NAME,
      environment: configData.ENDPOINT_ALX_APP_ENV,
      menuId: params.row.menuId,
      pageName: params.row.pageName,
      location: 'Frequent Table'
    })
  }

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F6F6F6",
    },
    "& .MuiDataGrid-columnHeader": {
      borderRight: "1px solid #C7C7C7",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer":
      {
        position: "absolute",
        right: "2vw",
        visibility: "visible",
        width: "unset",
      },
    "& .MuiDataGrid-columnHeaders .MuiDataGrid-menuIcon": {
      visibility: "visible",
      width: "unset",
    },
    "& .MuiIconButton-sizeSmall .MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon":
      {
        transition: "none",
        opacity: "unset",
      },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: "#000000",
      fontWeight: "600",
    },
  }));

  return (
    <Box className={styles.frequency_table_container}>
      <StyledDataGrid
        page={page}
        sortModel={sortModel}
        rows={rows(completeFrequencyList)}
        columns={columns}
        pageSize={defaultRowCount}
        rowsPerPageOptions={[defaultRowCount]}
        hideFooterPagination={frequencyList.length <= defaultRowCount}
        onRowClick={(params) => handleRowClick(params)}
        onSortModelChange={(sortModel) => setSortModel(sortModel)}
        onPageChange={(pageModel) => setPage(pageModel)}
      />
    </Box>
  );
};
