import React, { MouseEvent } from 'react';
import { Box, Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

interface CustomChipProps {
  label: string;
  onDelete: (event: MouseEvent<HTMLDivElement>) => void;
}

const CustomChip: React.FC<CustomChipProps> = ({ label, onDelete }) => (
  <Box component="span" onMouseDown={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
<Chip
      label={<span style={{ color: 'gray' }}>{label}</span>} 
      deleteIcon={<CancelIcon style={{color:'#00aebd'}} />}
      onDelete={onDelete}
      style={{ backgroundColor: '#E3FEFF', color: 'gray' }}
    />
  </Box>
);

export default CustomChip;