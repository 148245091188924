import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { models } from 'powerbi-client';
import { RootState } from 'state/store';
import { AsyncStatus } from 'common/Types/StateReferenceTypes';
import { ContentInit, FetchEmbedConfigDTO, ReportEmbedConfig } from "common/Types/EmbeddedContentTypes";
import { embedConfigRequest } from "./embeddedContentAPI";
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';
import configData from 'config.json';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from "common/Utils/telemetryUtil";
import { appTelemetry } from "services/TelemetryService";

export interface EmbeddedContentState {
  contentInit: ContentInit;
  enableRls: boolean;
  roles?: string[] | [];
  status: AsyncStatus;
  embedConfig: ReportEmbedConfig;
}

//TODO what needs to change here to make this hack no longer necessary?
//hack so jeff can see different report for now. TODO remove after nav bar is set up
let search = window.location.search;
let params = new URLSearchParams(search);
let rls = params.get('rls');

const initialState: EmbeddedContentState = {
  contentInit: {
    workspaceId: '',
    reportId: ''
  },
  status: 'idle',
  enableRls: rls && (rls === "true" || rls === "1") ? true : false,
  embedConfig: {
    canEdit: false,
    type: "report",
    embedToken: {
      expiration: "",
      token: "",
      tokenId: "",
    },
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    minutesToExpiration: 60,
    lastRefreshDate: "",
    reportName: undefined,
    settings: {
      background: models.BackgroundType.Transparent,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToWidth,
        pagesLayout: {},
      },
      panes: {
        filters: {
          visible: true,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  }
};

export const fetchEmbedConfig = createAsyncThunk(
  'embeddedContentFrame/fetchEmbedConfig',
  async (dto: FetchEmbedConfigDTO, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/powerbi-token/${dto.requestedContent.type}/${dto.reportPackName}`
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await embedConfigRequest(dto).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });

    return response;
  }
)

export const embeddedContentFrameSlice = createSlice({
  name: 'embeddedContentFrame',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmbedConfig.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmbedConfig.fulfilled, (state, action) => {
        state.status = 'idle';
        state.embedConfig = {
          ...state.embedConfig,
          ...action.payload
        }
        state.embedConfig.accessToken = action.payload.embedToken.token;
      })
  }
});

export const selectContentInit = (state: RootState) => state.embeddedContentFrame.contentInit;
export const selectEnableRls = (state: RootState) => state.embeddedContentFrame.enableRls;
export const selectEmbedConfig = (state: RootState) => state.embeddedContentFrame.embedConfig;
export const selectContentRefreshDate = (state: RootState) => state.embeddedContentFrame.embedConfig.lastRefreshDate;
export const selectAsyncStatus = (state: RootState) => state.embeddedContentFrame.status;

export default embeddedContentFrameSlice.reducer;