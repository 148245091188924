import axios from 'axios';
import { BladeMenuItemOption } from 'common/Types/BladeMenuTypes';
import { PreferredItem, PrefType, UserPreferenceDTO } from 'common/Types/UserPreferenceTypes';
import configData from 'config.json';

export const requestUserPreference = async (requestedPreference: PrefType) => {
  try {
    return axios.get(`${configData.ENDPOINT_ALX_APIBASEURL}/user-preferences/${requestedPreference}`)
      .then((response) => {      
        return response.data;
      }).catch((err) => {
        const error = (err as Error);
        throw new Error(`The requested ${requestedPreference} was not returned. Error: ${error.message}`);
      });
  } catch (err) {
    const error = (err as Error);
    throw new Error(`The request to the user preferences server failed with the following message: ${error.message}`);
  }
}

export const recordUserPreference = async (preferenceDto: UserPreferenceDTO) => {
  const { preferenceType, preference } = preferenceDto;
  try {
    return axios.post(`${configData.ENDPOINT_ALX_APIBASEURL}/user-preferences/${preferenceType}`,
      preference,
      {
        params: configData.ENDPOINT_ALX_REQUEST_CODE
      }
    )
    .then((response) => {
      return response.data;
      }).catch((err) => {
        const error = (err as Error);
        //will get back this commented code.
        //throw new Error(`The ${preferenceType} was not recorded. Error: ${error.message}`);
      });
  } catch (err) {
    const error = (err as Error);
    throw new Error(`The request to the user preferences server failed with the following message: ${error.message}`);
  }
}

export const returnCompletePreferences = (favorites: PreferredItem[], allReportPages: BladeMenuItemOption[]): PreferredItem[] => {
  return favorites.map((content) => {
    const matchingPageData = allReportPages.find((page) => page.name === content.pageName);
    return {
      ...content,
      displayName: matchingPageData?.displayName ?? ''
    };
  })
}