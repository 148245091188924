import React from 'react';
import ImageListItem, { imageListItemClasses } from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'state/hooks';
import { selectUserFavorites } from '../preferences/preferencesSlice';
import { selectAllPages } from '../../bladeMenu/bladeMenuSlice';
import { FavoriteCard } from './favoriteCard/FavoriteCard';
import styles from './favoritesDeck.module.scss';
import { ReactComponent as ReportIcon } from 'common/Styles/svg_icons/Icons_Filled_clipboard-list_24px.svg';
import { ReactComponent as UserMgmtIcon } from 'common/Styles/svg_icons/Icons_Filled_users_24px.svg';
import { ReactComponent as PatientActivityAndStatus } from 'common/Styles/svg_icons/Icons_Filled_address-card_24px.svg';
import { ReactComponent as InventoryIcon } from 'common/Styles/svg_icons/Icons_Filled_box-open_24px.svg';
import { ReactComponent as SiteStatusesIcon } from 'common/Styles/svg_icons/Icons_Filled_hospital_24px.svg';
import { returnCompletePreferences } from '../preferences/preferencesAPI';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const FavoritesDeck = () => {
  const favorites = useAppSelector(selectUserFavorites);
  const allReportPages = useAppSelector(selectAllPages);
  const completeFavorites = returnCompletePreferences(favorites, allReportPages);

  const renderCards = () =>
    completeFavorites.map((favorite, index) => { 
      type cardData = {
        elmt: JSX.Element;
        class: string;
      }
      
      const cardIcon = (): cardData  => {
        switch (favorite.menuName) {
          case "User Management":
            return {
              elmt: <UserMgmtIcon />,
              class: styles.user_mgmt_avatar 
            } 
          case "Patient Activity & Status":
            return {
              elmt: <PatientActivityAndStatus />,
              class: styles.patient_activity_avatar
            };
          case "Inventory":
            return {
              elmt: <InventoryIcon />,
              class: styles.inventory_avatar
            }
          case "Site Statuses":
            return {
              elmt: <SiteStatusesIcon />,
              class: styles.site_statuses_avatar
            }
          default:
            return {
              elmt: <ReportIcon />,
              class: styles.card_avatar
            }
        }
      };

      const temp = cardIcon();

      return (
        <ImageListItem key={`${favorite}_00${index}`}>
          <FavoriteCard favorite={favorite} icon={temp.elmt} avatar={temp.class}  />
        </ImageListItem>
      );
    });
    
  
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 570,
        md: 670,
        lg: 770,
        xl: 970
      }
    }
  });


  const renderDeck = () => {
    if (!!favorites.length) {
      return (
        <Box 
        sx={{ 
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeate(3, 1fr)"
          },
          [`& .${imageListItemClasses.root}`]: {
            display: "flex",
            flexDirection: "column"
          }
        }} 
          className={styles.favorites_deck}
        >
          {renderCards()}
        </Box>
      );
    } else {
      return (
        <Typography className={styles.empty_deck}>
          Add your favorite reports here for quick access!
        </Typography>
      );
    }
  };

  return <ThemeProvider theme={theme}>{renderDeck()}</ThemeProvider>;
};
